import React from "react";
import Contact from "../components/js/Contact";
import Header from "../components/js/Header";
import Banner from "../components/js/Banner";
import Footer from "../components/js/Footer";
import TopBar from "../components/js/TopBar";
const ContactPage = () => {
  return (
    <div>
      <TopBar/>
      <Header path='contact'/>
      <Banner path='Contact Us'/>
      <Contact />
      <Footer/>
    </div>
  );
};

export default ContactPage;
