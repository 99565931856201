import { Markup } from 'interweave';
import React from 'react'
import { useLocation } from 'react-router-dom'
import '../css/morenews.css';
import '../css/morenews.css'
function MoreNews() {
  const {state} = useLocation();

  return (
    <center>
        <div style={{margin:'5% auto'}}>
            <h2 className='section-title'>{state.title}</h2>
            <img src={state.img} alt='image not found!' style={{margin:'5% 10%', width:'20%'}} className='news-img'/>
            <center style={{margin:'2% 20%', textAlign:'justify', color:'black !important'}}>
                <Markup className='markup' content={state.content} />
            </center>
        </div>
    </center>
  )
}

export default MoreNews