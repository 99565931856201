import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCxG7QPsu8FM1z2vxCntj_7oan5y1dMzws",
  authDomain: "melkalit.firebaseapp.com",
  projectId: "melkalit",
  storageBucket: "melkalit.appspot.com",
  messagingSenderId: "651746690936",
  appId: "1:651746690936:web:199547438fffa783e52a8d",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
