import React, { useEffect } from "react";
import "../css/hero.css";
import "../css/style.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
import { caretBackOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
const Hero = () => {
  useEffect(() => {
    const heroSlider = document.querySelector("[data-hero-slider]");
    const heroSliderItems = document.querySelectorAll(
      "[data-hero-slider-item]"
    );
    const heroSliderPrevBtn = document.querySelector("[data-prev-btn]");
    const heroSliderNextBtn = document.querySelector("[data-next-btn]");

    let currentSlidePos = 0;
    let lastActiveSliderItem = heroSliderItems[0];

    const updateSliderPos = function () {
      lastActiveSliderItem.classList.remove("active");
      heroSliderItems[currentSlidePos].classList.add("active");
      lastActiveSliderItem = heroSliderItems[currentSlidePos];
    };

    const slideNext = function () {
      if (currentSlidePos >= heroSliderItems.length - 1) {
        currentSlidePos = 0;
      } else {
        currentSlidePos++;
      }

      updateSliderPos();
    };

    heroSliderNextBtn.addEventListener("click", slideNext);

    const slidePrev = function () {
      if (currentSlidePos <= 0) {
        currentSlidePos = heroSliderItems.length - 1;
      } else {
        currentSlidePos--;
      }

      updateSliderPos();
    };

    heroSliderPrevBtn.addEventListener("click", slidePrev);

    /**
     * auto slide
     */

    let autoSlideInterval;

    const autoSlide = function () {
      autoSlideInterval = setInterval(function () {
        slideNext();
      }, 7000);
    };

    [heroSliderNextBtn, heroSliderPrevBtn].forEach((btn) => {
      btn.addEventListener("mouseover", function () {
        clearInterval(autoSlideInterval);
      });

      btn.addEventListener("mouseout", autoSlide);
    });

    window.addEventListener("load", autoSlide);

    // Cleanup the event listeners when the component unmounts
    return () => {
      [heroSliderNextBtn, heroSliderPrevBtn].forEach((btn) => {
        btn.removeEventListener("mouseover", function () {
          clearInterval(autoSlideInterval);
        });

        btn.removeEventListener("mouseout", autoSlide);
      });

      window.removeEventListener("load", autoSlide);
    };
  }, []);

  return (
    <section class="hero text-center" aria-label="home" id="home">
      <ul class="hero-slider" data-hero-slider>
        <li class="slider-item active" data-hero-slider-item>
          <div class="slider-bg">
            <img
              src="./assets/images/hero-slider-1.jpg"
              width="1880"
              height="950"
              alt=""
              class="img-cover"
            />
          </div>
           <div className="overlay-text ">
          <p class="label-2 section-subtitle slider-reveal">Ethipian Coffee</p>

          <h1 class="display-1 hero-title slider-reveal">
             Sip the world's finest. <br/> Explore our coffee exports.
          </h1>

          <p class="body-2 hero-text slider-reveal">
            Joy for you and your family.
          </p>

          <Link to="/service" class="btn btn-primary slider-reveal">
            <span class="text text-1">View Our Services</span>

            <span class="text text-2" aria-hidden="true">
              View Our Services
            </span>
          </Link>
          </div>
          <div class="image-overlay"></div>

        </li>

        <li class="slider-item" data-hero-slider-item>
          <div class="slider-bg">
            <img
              src="./assets/images/hero-slider-2.jpg"
              width="1880"
              height="950"
              alt=""
              class="img-cover"
            />
          </div>
          <div className="overlay-text ">
          <p class="label-2 section-subtitle slider-reveal">
          Ethipian Coffee
          </p>

          <h1 class="display-1 hero-title slider-reveal">
          Crafted with care, shared with passion. <br/> Enjoy our export selection.
          </h1>

          <p class="body-2 hero-text slider-reveal">
          Joy for you and your family.
          </p>

          <Link to="/service" class="btn btn-primary slider-reveal">
            <span class="text text-1">View Our Services</span>

            <span class="text text-2" aria-hidden="true">
              View Our Services
            </span>
          </Link>
          </div>
          <div class="image-overlay"></div>
        </li>

        <li class="slider-item" data-hero-slider-item>
          <div class="slider-bg">
            <img
              src="./assets/images/hero-slider-3.jpg"
              width="1880"
              height="950"
              alt=""
              class="img-cover"
            />
          </div>
          <div className="overlay-text ">
          <p class="label-2 section-subtitle slider-reveal">
          Ethipian Coffee
          </p>

          <h1 class="display-1 hero-title slider-reveal">
          From farm to cup, perfection in every brew.<br/> Experience our coffee exports.
          </h1>

          <p class="body-2 hero-text slider-reveal">
          Joy for you and your family.
          </p>

          <Link to="/service" class="btn btn-primary slider-reveal">
            <span class="text text-1">View Our Services</span>

            <span class="text text-2" aria-hidden="true">
              View Our Services
            </span>
          </Link>
          </div>
          <div class="image-overlay"></div>
        </li>
      </ul>

      <button
        className="slider-btn prev"
        aria-label="slide to previous"
        data-prev-btn
      >
        <IonIcon icon={caretBackOutline} size="large"></IonIcon>
      </button>

      <button
        className="slider-btn next"
        aria-label="slide to next"
        data-next-btn
      >
        <IonIcon icon={caretForwardOutline} size="large"></IonIcon>
      </button>
    </section>
  );
};

export default Hero;
