import React,{useState, useEffect} from 'react'
import TopBar from '../components/js/TopBar';
import Header from '../components/js/Header';
import Hero from '../components/js/Hero';
import About from '../components/js/About';
import Footer from '../components/js/Footer';
import Contact from '../components/js/Contact';
import Service from '../components/js/Service';
import Gallery from '../components/js/Gallery';
import News from '../components/js/News';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { serverTimestamp } from "firebase/compat/firestore";
import { db, storage } from "../firebase.js";
import Videos from '../components/js/Videos';

const Home = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "videos"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setVideos(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  const [news, setNews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "news"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setNews(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      <TopBar/>
      <Header path='home'/>
      <Hero/>
      <About/>
      <Service/>
      {news.length != 0 && <News/>}
      <Gallery/>
      {videos.length != 0 && <Videos/>}
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home