import React from "react";
import Header from "../components/js/Header";
import Banner from "../components/js/Banner";
import News from "../components/js/News";
import Footer from "../components/js/Footer";
import TopBar from "../components/js/TopBar";
import NewsNext from "../components/js/NewsNext";

const NewsPage = () => {
  return (
    <div>
      <TopBar/>
      <Header path='news'/>
      <Banner path="News" />
      <NewsNext/>
      <Footer />
    </div>
  );
};

export default NewsPage;
