import React from 'react';
import '../css/contact.css';

const Contact = () => {
  return (


    <section class="contact">

        <div class="contact-content">
        <p class="label-2 section-subtitle" id="about-label">Get in touch</p>

        <h2 class="headline-1 section-title">Contact Us</h2>
        <form action="" class="contact-form">
        
          <input type="text" name="name" placeholder="Full Name" required class="input-field"/>
          <input type="text" name="name" placeholder="Subject" required class="input-field"/>
          <input type="email" name="email" placeholder="Email" required class="input-field"/>
          <textarea name="message" id="" className='message' placeholder='Message' ></textarea>
          <center>
            <button type="submit" class="btn btn-primary">Send</button>
          </center>

        </form>
        
        </div>

        <figure class="contact-banner">
          <img src="./assets/images/about-banner.jpg" width="960" height="846" loading="lazy" alt="Rhinoceros"
            class="img-cover"/>
        </figure>

      </section>

       
  )
}

export default Contact