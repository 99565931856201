import React, { useState, useEffect } from "react";
import "../css/videos.css";
import ReactPlayer from "react-player/youtube";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { serverTimestamp } from "firebase/compat/firestore";
import { db, storage } from "../../firebase.js";
import { useNavigate } from "react-router-dom";

const Videos = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "videos"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setVideos(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);
  return (
    <section className="section video" id="video" aria-label="video">
      <div className="container">
        <p className="section-subtitle">???</p>

        <h2 className="h2 section-title">Videos</h2>

        <ul className="video-list">
          {videos.map((video) => {
            return (
              <li>
                <div className="video-card">
                  <figure className="card-banner img-holder">
                    <ReactPlayer url={video.link} className="player" />
                  </figure>
                  <div className="card-content">
                    <h3
                      className="h3 card-title"
                      style={{
                        width: "100%",
                        height: "10vh",
                        overflowY: "scroll",
                      }}
                    >
                      {video.description}
                    </h3>

                    <a href={video.link} target="_blank">
                      <button className="video_button">Watch</button>
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Videos;
