import React from 'react'
import Header from '../components/js/Header'
import Banner from '../components/js/Banner'
import Service from '../components/js/Service'
import Footer from '../components/js/Footer'
import TopBar from '../components/js/TopBar'

const ServicePage = () => {
  return (
    <div>
      <TopBar/>
      <Header path='service'/>
      <Banner path="Services" />
      <Service />
      <Footer />
    </div>
  )
}

export default ServicePage