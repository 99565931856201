import React,{useState, useEffect} from 'react'
import '../css/news.css';
import '../css/style.css';
import { Link, useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase.js";
import { Button, Card, Modal } from "flowbite-react";


const NewsNext = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "news"));
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        setNews(list);
        console.log(list);
      },
      (error) => {
        console.log(error);
      },
      []
    );

    return () => {
      unsub();
    };
  }, []);

  const navigate = useNavigate();

  const handleDetail = (row) => {
    navigate("/detail", { state: { ...row } });
  };


  return (
    <section class="section event bg-black-10" aria-label="event">
    <div class="container">

      <p class="section-subtitle label-2 text-center">Recent Updates</p>

      <h2 class="section-title headline-1 text-center">News & Upcoming Event</h2>

      <ul class="grid-list">
      {news.map((n)=> {
       return <li>
          <div class="event-card has-before hover:shine">

            <div class="card-banner img-holder" style={{width: '350', height:' 450'}}>
              <img src={n.img} width="350" height="450" loading="lazy"
                alt="Flavour so good you’ll try to eat with your eyes." className="img-cover" style={{height:'50vh'}}/>
            </div>

            <div class="card-content">
              <p class="card-subtitle label-2 text-center">{n.title}</p>

            </div>
            

          </div>
          <button
          onClick={(e) => {
            handleDetail(n);
          }}
          target="_blank"
          className="video_button"
          >Read More</button>
        </li>
      })}
        

      </ul>


    </div>
  </section>
  )
}

export default NewsNext



// <time class="publish-date label-2" datetime="2022-09-15">15/09/2022</time>