import React from "react";
import "../css/topbar.css";
import "../css/style.css";

const TopBar = () => {
  return (
    <div class="topbar">
      <div class="container">
        <address class="topbar-item">
          <div class="icon">
            <ion-icon name="location-outline" aria-hidden="true"></ion-icon>
          </div>

          <span class="span">
            Shimeket Comercial Center, Bisrate Gebriel, Addis Ababa, Ethiopia
          </span>
        </address>

        <div class="separator"></div>

        <div class="topbar-item item-2">
          <div class="icon">
            <ion-icon name="time-outline" aria-hidden="true"></ion-icon>
          </div>

          <span class="span">Mon - Sat : 8.00 am to 5.00 pm</span>
        </div>

        <a href="tel:+2519780398803" class="topbar-item link">
          <div class="icon">
            <ion-icon name="call-outline" aria-hidden="true"></ion-icon>
          </div>

          <span class="span">+251-978-039803 / +251-911-206528</span>
        </a>

        <div class="separator"></div>

        <a href="mailto:melkalaticoffee@gmail.com" class="topbar-item link">
          <div class="icon">
            <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>
          </div>

          <span class="span">melkalaticoffee@gmail.com</span>
        </a>
      </div>
    </div>
  );
};

export default TopBar;
