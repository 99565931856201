import React from "react";
import "../css/about.css";
import { IonIcon } from "@ionic/react";
import { checkboxOutline } from "ionicons/icons";

const About_Next = () => {
  return (
    <div>
      <section
        class="section about text-center"
        aria-labelledby="about-label"
        id="about"
      >
        <div class="container">
          <div class="about-content">
            <p class="label-2 section-subtitle" id="about-label">
              Our Story
            </p>

            <h2 class="headline-1 section-title">About Us</h2>

            <p class="section-text">
              Melkalati Trading PLC is a private green coffee exporting company
              in Ethiopia founded in 2010 G.C. by Mr Dereje Alemayehu and Mr
              Ababa Neri who have more than 20 years of experience in the coffee
              business. Our company mainly focuses on exporting speciality and
              pereminum quality Ethiopian cofee to the world. We have exprianced
              human resources that have good experience in the coffee industry.
              Our empolyees are working in a very good spirt to service
              customers 2 hours a day and 7 days a week. Currently we have 60
              permanent and 120 seasonal employees who are working in the coffee
              export business.
            </p>
          </div>

          <figure class="about-banner">
            <img
              src="./assets/images/about-banner.jpg"
              loading="lazy"
              alt="about banner"
              class="w-100"
              data-parallax-item
              data-parallax-speed="1"
            />

            <div class="abs-img abs-img-2 has-before"></div>
          </figure>
        </div>
      </section>
      <section class="section donate" id="donate">
        <div class="container">
          <h2
            className=""
            style={{
              marginBottom: "2%",
              color: "#E4C590",
              textAlign: "center",
            }}
          >
            Vision and Mission
          </h2>
          <ul class="donate-list">
            <li className="vision">
              <div class="donate-card">
                <div class="card-content">
                  <h3 class="h3 card-title">Vision</h3>
                  <p class="card-text">
                    To be among the lists of top ten producer and exporter of
                    sepciality Arabica coffee in Ethiopia by 2030 G.C.
                  </p>
                </div>
              </div>
            </li>
            <li className="mission">
              <div class="donate-card">
                <div class="card-content">
                  <h3 class="h3 card-title">Mission</h3>
                  <p class="card-text">
                    Having a well-known brand equity in preparation and
                    providing the market with high-quality Ethiopian coffee.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="section about-nexts" id="about-nexts">
        <div class="container">
          <h2
            className=""
            style={{
              marginBottom: "2%",
              color: "#E4C590",
              textAlign: "center",
            }}
          >
            A track record of fair trading and excellent customer service.
          </h2>
          <ul class="about-next-flex">
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Orgin </h3>

                <p class="card-text">
                  Ethiopia is considered the birth place of coffee
                </p>
              </div>
            </li>
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Altitude </h3>

                <p class="card-text">
                  The general rule of thumb: The higer the altitude is the higer
                  the quality.
                </p>
              </div>
            </li>
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Variety </h3>

                <p class="card-text">
                  Ethiopia has more than a thousand of diversified coffee types.
                </p>
              </div>
            </li>
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Processing</h3>

                <p class="card-text">
                  The processing methods that we use are very much unique in
                  nature.
                </p>
              </div>
            </li>
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Plant Care</h3>

                <p class="card-text">
                  We care the plants and harvested manually by hand.
                </p>
              </div>
            </li>
            <li>
              <div class="about-next-card">
                <h3 class="h3 card-title">Tradition and Culture</h3>

                <p class="card-text">
                  Coffe is in our blood. We have been coming together over coffe
                  for centuires.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="section author" id="author">
        <div className="container">
          <figure
            className="author-banner img-holder"
            style={{ width: "700", height: "720" }}
          >
            <img
              src="./assets/images/hero-slider-1.jpg"
              width="700"
              height="720"
              loading="lazy"
              alt="Martin Jenny"
              className="img-cover"
            />
          </figure>

          <div className="author-content">
            <h2 className="h2 card-title">Quality Policy</h2>

            <div className="card-text">
              At Melkalati we are highly conscious of the fact that the qualtiy
              of our beans is what sets us apart. Besides our company has brand
              slogan is:
            </div>
            <div
              className=""
              style={{
                justifyContent: "center",
                color: "black",
                margin: "5%",
                fontSize: "20px",
                fontStyle: "bold",
              }}
            >
              "We are committed to quality and customer satisfaction"
            </div>
          </div>
        </div>
      </section>
      <div>
        <center>
          <h2
            className=""
            style={{
              marginBottom: "2%",
              color: "#E4C590",
              textAlign: "center",
            }}
          >
            List of our coffee processing industry
          </h2>
        </center>
        <div className="industry-cards">
          <div className="industry-card">
            <h3 className="industry-card-title">Washing Station</h3>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "black", fontSize: "20px" }}
              ></IonIcon>
              Worka Sareji Washing Stations: found in Gedeb woreda Gedeo zone
              produces fully washed Yirgacheffe Worka Sareji Coffee.
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "black", fontSize: "20px" }}
              ></IonIcon>
              Tore Badiya washing stations: found in kochere wereda Hanku kebele
              produces fully washed kochene Tore Basiye Coffee.{" "}
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "black", fontSize: "20px" }}
              ></IonIcon>
              Hafursa washing stations: found in Yirgachefee woreda Hafursa
              kebele produces fully washed Yirgacheffee Hafrusa Coffee.
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "black", fontSize: "20px" }}
              ></IonIcon>
              Dara washing stations: found in Dara wereda Sidama region prduces
              fully washed Sidama coffee and alternatively produces Natural
              sidama G1, G2, G3 and G4 coffee.
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "black", fontSize: "20px" }}
              ></IonIcon>
              Dari Kojua wahsing stations found in west Gugi zone Ambela wamena
              district in Dari Kojuwa kebele produces fully washed Guji coffess
              alternatively prodcues natural Guji Dari Kojuwa G1, G2, G3 and G4
              coffee.
            </p>
          </div>

          <div className="industry-card">
            <h3 className="industry-card-title">
              Dry Coffee processing Industry
            </h3>

            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "#1e1711", fontSize: "0.5vw" }}
              ></IonIcon>
              Awda dry cofee processing industry found in Yirgacheffe town near
              sede, and Hafursa coffee farmers. The industry specialises in
              preparation of natural Yirgachefee unique lots such as , Hafursa,
              Konga, Koke, Edido and Gerse coffees mainly grade 1 and grade 2 in
              this regard the industry were fully fledged and very big having
              brasilian dry coffee pulping machine producing 12.75MT clean
              coffee per day. Besides it possess 250 standing beds with length
              of 25M by using this collects 1 million kg red cherry coffee for
              the preparation of the above mentioined lots per one coffee season
              or a year as a result the industry ranked first in capacity and
              overall parameters found in Yirgacheffe woreda and Gedeo zone
              level.
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "#1e1711", fontSize: "20px" }}
              ></IonIcon>
              Chelelektu dry coffee processing industry found in kochere woreda
              chelelektu town produces natural kochere G1, G2 and G3 coffee.
            </p>
            <p className="industry-bullet">
              <IonIcon
                icon={checkboxOutline}
                size="large"
                className="industry-icon"
                style={{ color: "#1e1711", fontSize: "20px" }}
              ></IonIcon>
              Dari Kojuwa dry cofee processing industry found in west Guji zone,
              Hambelawamena woreda Dari kojua kebele produces natural Guji G1,
              G2, G3 and G4 coffees.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_Next;
