import React from 'react'
import Banner from '../components/js/Banner'
import Header from '../components/js/Header'
import Footer from '../components/js/Footer'
import About from '../components/js/About'
import TopBar from '../components/js/TopBar'
import About_Next from '../components/js/About_Next'
const AboutPage = () => {
  return (
    <div>
      <TopBar/>
      <Header path='about'/>
      <Banner path='About Us'/>
      <About_Next/>
      <Footer/>
    </div>
  )
}

export default AboutPage