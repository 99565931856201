import React from 'react';
import '../css/service.css';
import { IonIcon } from "@ionic/react";
import { logoFacebook,logoGoogle, logoTwitter, logoLinkedin } from "ionicons/icons";

const Service = () => {
  return (
    <section class="section services" id="services" aria-label="services">
        
        <p class="label-2 section-subtitle" id="about-label">What we provide</p>

        <h2 class="headline-1 section-title">Services</h2>

        <ul class="services-list">

            <li class="services-item ">
              <div class="services-card">

                <center class="card-icon">
                  <img src='./assets/images/export.png'size="large" className='service-icon' style={{color:'black', width:'10%'}}></img>
                </center>

                <h3 class="h3 card-title">Coffee Export</h3>

                <p class="card-text service-text">
                Melkalati Trading PLC exports high-quality Ethiopian coffee to Australia, China, and Japan, where it is highly valued for its unique flavors and rich heritage. Specialty coffee shops and consumers in these markets seek Melkalati's premium beans from regions like Yirgacheffe, Sidamo, Harar, and Guji.
                </p>

              </div>
            </li>

            <li class="services-item">
              <div class="services-card">

              <center class="card-icon">
              <img src='./assets/images/processing.png'size="large" className='service-icon'   style={{color:'black', width:'10%'}}></img>
            </center>

                <h3 class="h3 card-title">Coffee Processing</h3>

                <p class="card-text service-text">
                Melkalati Trading PLC meticulously processes high-quality Ethiopian coffee, selecting ripe cherries from regions like Yirgacheffe and Sidamo and using both traditional and modern methods. Emphasizing sustainability and ethical practices, they ensure only the finest beans are exported for a superior coffee experience.
                </p>

              </div>
            </li>


          </ul>
      </section>
  )
}

export default Service