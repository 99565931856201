import React from 'react';
import MoreNews from '../components/js/MoreNews'
import Header from '../components/js/Header';
import Banner from '../components/js/Banner';
import Footer from '../components/js/Footer';
import TopBar from '../components/js/TopBar';
const NewsDetailPage = () => {
  return (
    <div>
      <TopBar/>
      <Header path='news'/>
      <Banner path="News & Updates" />
      <MoreNews />
      <Footer />
    </div>
  )
}

export default NewsDetailPage