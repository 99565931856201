import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import NewsPage from "./pages/NewsPage";
import ContactPage from "./pages/ContactPage";
import NewsDetailPage from "./pages/NewsDetailPage.js";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/service" element={<ServicePage />} />
      <Route path="/detail" element={< NewsDetailPage/>} />
    </Routes>
  );
}

export default App;



// <Route path="/videos" element={<Videos />} />
// <Route path="/detail" element={<Details />} />