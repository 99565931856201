import React from 'react';
import '../css/about.css';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section class="section about text-center" aria-labelledby="about-label" id="about">
        <div class="container">

          <div class="about-content">

            <p class="label-2 section-subtitle" id="about-label">Our Story</p>

            <h2 class="headline-1 section-title">About Us</h2>

            <p class="section-text">
              Melkalati Trading PLC is a private green coffee exporting company in Ethiopia founded in 18 June, 2018 G.C. by Mr Dereje
              Alemayehu and Mrs Ababa Neri who have more than 20 years of experience in the coffee business. Our company mainly focuses on exporting speciality 
              and pereminum quality Ethiopian cofee to the world. We have exprianced human resources that have good experience in the coffee industry. Our empolyees are working in a very good 
              spirt to service customers 2 hours a day and 7 days a week. Currently we have 60 permanent and 120 seasonal employees who are working in the coffee export
              business.
            </p>


            <Link to='/about' class="btn btn-primary">
              <span class="text text-1">Read More</span>

              <span class="text text-2" aria-hidden="true">Read More</span>
            </Link>

          </div>

          <figure class="about-banner">

            <img src="./assets/images/about-banner.jpg" loading="lazy" alt="about banner"
              class="w-100" data-parallax-item data-parallax-speed="1"/>


            <div class="abs-img abs-img-2 has-before">
            
            </div>

          </figure>

        </div>
        
      </section>
  )
}

export default About

// <img src="./assets/images/badge-2.png" width="133" height="134" loading="lazy" alt=""/>