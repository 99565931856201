import React, { useEffect } from "react";
import "../css/style.css";
import "../css/header.css";
import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { ListItem } from "flowbite-react";

const Header = (props) => {
  useEffect(() => {
    const header = document.getElementById("header");

    if (!header) {
      console.error("Header element not found");
      return;
    }

    let lastScrollPos = 0;

    const hideHeader = function () {
      const isScrollBottom = lastScrollPos < window.scrollY;
      if (isScrollBottom) {
        header.classList.add("hide");
      } else {
        header.classList.remove("hide");
      }

      lastScrollPos = window.scrollY;
    };

    const onScroll = function () {
      if (window.scrollY >= 50) {
        header.classList.add("active");
        hideHeader();
      } else {
        header.classList.remove("active");
      }
    };

    window.addEventListener("scroll", onScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0, 1",
  justifyContent: "flex-end",
}));


const theme = useTheme();
const [open, setOpen] = React.useState(false);

const handleDrawerOpen = () => {
  setOpen(true);
};

const handleDrawerClose = () => {
  setOpen(false);
};

  return (
    <header className="header" data-header id="header">
      <div className="container">
        <Link to="/" className="logo">
          <img src="./assets/images/logo.png" alt="" className="logo-image" style={{ width: "10% !important" }} />
        </Link>

        <nav className="navbar" data-navbar>
          <button
            className="close-btn"
            aria-label="close menu"
            data-nav-toggler
          >
            <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
          </button>

          <Link to="/" className="logo">
            <img
              src="./assets/images/logo.png"
              
              alt="Melkalati - Home"
            />
          </Link>

          <ul className="navbar-list">
            <li className="navbar-item">
              <Link
                to="/"
                href="#home"
                className={`navbar-link  ${
                  props.path == "home" ? "active" : ""
                }`}
              >
                <div className="separator"></div>
                <span className="span">Home</span>
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/about"
                className={`navbar-link  ${
                  props.path == "about" ? "active" : ""
                }`}
              >
                <div className="separator"></div>
                <span className="span">About Us</span>
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/service"
                className={`navbar-link  ${
                  props.path == "service" ? "active" : ""
                }`}
              >
                <div className="separator"></div>
                <span className="span">Services</span>
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/news"
                className={`navbar-link  ${
                  props.path == "news" ? "active" : ""
                }`}
              >
                <div className="separator"></div>
                <span className="span">News & Updates</span>
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/contact"
                className={`navbar-link  ${
                  props.path == "contact" ? "active" : ""
                }`}
              >
                <div className="separator"></div>
                <span className="span">Contact</span>
              </Link>
            </li>
          </ul>

          <div className="text-center">
            <p className="headline-1 navbar-title">Visit Us</p>
            <address className="body-4">
              Shimeket Building, Bisrate Gebriel, <br />
              Addis Ababa, Ethiopia
            </address>
            <p className="body-4 navbar-text">Open: 8.00 am - 5.00pm</p>
            <a
              href="mailto:melkalaticoffee@gmail.com"
              className="body-4 sidebar-link"
            >
              melkalaticoffee@gmail.com
            </a>
            <div className="separator"></div>
            <a href="tel:+251-978-0398803" className="body-1 contact-number ">
              +251-978-039503
            </a>
          </div>
        </nav>

        <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
              overflowY:'scroll'
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <div style={{ left: "0" }}>
              <center>
                <img src="./assets/images/logo.png" className="logo-image"  width="160" height="50" alt="Melkalati - Home" />
              </center>
              </div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ion-icon name="arrow-dropright"></ion-icon>
                ) : (
                  <ion-icon name="arrow-dropleft"></ion-icon>
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem disablePadding>
                <Link to="/">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="home"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Home"} />
                  </ListItemButton>
                </Link>
                <Link to="/about">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="information-circle-outline"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"About Us"} />
                  </ListItemButton>
                </Link>
                <Link to="/service">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="cog"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Services"} />
                  </ListItemButton>
                </Link>
                <Link to="/news">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="megaphone"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"News & Updates"} />
                  </ListItemButton>
                </Link>
                <Link to="/contact">
                  <ListItemButton>
                    <ListItemIcon>
                      <ion-icon
                        name="call"
                        style={{ color: "black" }}
                      ></ion-icon>
                    </ListItemIcon>
                    <ListItemText primary={"Contact Us"} />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
            <Divider />
            <div className="text-center" style={{ marginTop: "10%" }}>
              <h1 style={{ fontSize: "30px" }} className="body-1">
                Visit Us
              </h1>

              <address className="body-4">Addis Ababa, Ethiopia</address>

              <a style={{ color: "black" }}>Open: 8am - 5pm</a>

              <h1 style={{ marginTop: "10px" }} className="body-1">
                Contact Us
              </h1>

              <a
                href="tel:+251978039503"
                className=" contact-number hover-underline"
              >
                +251978039503
              </a>
              <a
                href="mailto:info@pathconsulting.com"
                className="body-4 sidebar-link"
              >
                info@melkalatitradingplc.com
              </a>
            </div>
          </Drawer>
          
          <button
            className="nav-open-btn"
            aria-label="open Service"
            data-nav-toggler
            onClick={handleDrawerOpen}
            style={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>

        <div className="overlay" data-nav-toggler data-overlay></div>
      </div>

    </header>
  );
};

export default Header;
