import React from "react";
import "../css/gallery.css";

const Gallery = () => {
  return (
    <div className="gallery">

    <h2 class="headline-1 section-title">Gallery</h2>

      <div class="row">
        <div class="column">
          <img src="./assets/images/gallery-1.jpg"  style={{width:"100%"}} />
          <img src="./assets/images/gallery-2.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-4.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-12.jpg" style={{width:"100%"}} />
        </div>
        <div class="column">
          <img src="./assets/images/gallery-5.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-8.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-6.jpeg"  style={{width:"100%"}} />
          <img src="./assets/images/gallery-11.jpg"  style={{width:"100%"}} />
          
        </div>
        <div class="column">
          <img src="./assets/images/gallery-3.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-10.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-7.jpg" style={{width:"100%"}} />
          <img src="./assets/images/gallery-9.jpg" style={{width:"100%"}} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
